import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Helmet from "react-helmet";
import Button from "../components/Utility/button";

export default class NotFoundPage extends React.Component { 
  render() {
    const { data } = this.props

    return (
        <React.Fragment>
            <Helmet>
                <title>404: Not Found</title>
            </Helmet>
            <Layout>
                <div class="not-found-container rich-text">
                    <h1>Page Not Found</h1>
                    <p>The page you were looking for does not exist.</p>
                    <Button buttonLink="/" buttonText="Return to Homepage" buttonColor="#FFFFFF" buttonBGColor="#006B2D" />
                </div>
            </Layout>
        </React.Fragment>
    )
  }
}